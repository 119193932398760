"use client";

import React from "react";
import { Button, Link } from "@nextui-org/react";
import { useTranslations } from "next-intl";

import ProductImageSwiper from "../image-swiper/component";
import ProductTooltipSpecification from "../tooltip";
import { Product as ProductInterface } from "../../../app/api/models/types";

import { bodyText } from "@/components/primitives";
import { IconChatCircle } from "@/icons/svgr";
import { button } from "@/components/button";
import ErrorBoundary from "@/components/error-boundary";

interface Props {
  product: ProductInterface;
}

export default function ProductDetail({ product }: Props): JSX.Element {
  const t = useTranslations("product");

  return (
    <ErrorBoundary>
      <ProductTooltipSpecification product={product} />

      <ProductImageSwiper product={product} />

      <div className="flex flex-col gap-2xl items-start text-left">
        <div className={bodyText({ size: "2xl" })}>
          {t(product.description)}
        </div>
        <Button
          as={Link}
          className={button({
            color: "primary",
            radius: "full",
            size: "md",
          })}
          href="#order"
          startContent={<IconChatCircle />}
        >
          {t("interested-model")}
        </Button>
      </div>
    </ErrorBoundary>
  );
}
