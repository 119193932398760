import { useContext } from "react";
import clsx from "clsx";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Link,
  useDisclosure,
} from "@nextui-org/react";
import { useLocale, useTranslations } from "next-intl";

import { Product as ProductInterface } from "../../../../app/api/models/types";
import {
  getProductImageFolder,
  getProductLink,
} from "../../../../utils/products";
import ProductTags from "../../tags";
import ProductCutsRange from "../../cuts-range";
import ProductCutsDetailed from "../../cuts-detailed";
import { ProductDetailSpecification } from "../../product-detail";

import { bodyText } from "@/components/primitives";
import {
  TechnicalSpecificationKey,
  TechnicalSpecificationLabelKey,
} from "@/app/api/models/enums";
import { TECHNICAL_SPECIFICATION_LABELS_SHORT } from "@/consts/product/technical-speficiation";
import {
  IconArrowRight,
  IconCrossSmall,
  IconInfo,
  IconSize,
} from "@/icons/svgr";
import { ProductContext } from "@/contexts/product";
import { button } from "@/components/button";
import useWindowSize from "@/hooks/useWindowSize";

export default function HighlightedProduct(product: ProductInterface) {
  const windowSize = useWindowSize();
  const { handleCuttingRange } = useContext(ProductContext);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const t = useTranslations("product");
  const tCommon = useTranslations("common");
  const locale = useLocale();

  const { name, category } = product;

  const productLink = getProductLink(product, locale);
  const imageSrc = `${getProductImageFolder(product)}/${product.gallery[0]}.png`;
  const technicalData = product[TechnicalSpecificationKey.technical_data];
  const cuttingRange = Object.entries(
    product[TechnicalSpecificationKey.cutting_range],
  ).filter(([_label, value]) => value);

  return (
    <article className="w-full grid lg:grid-cols-12 xl:grid-cols-10 items-end lg:items-start gap-y-xl lg:gap-x-section h-full px-md md:px-0">
      <div className="group col-span-12 lg:col-span-5 xl:col-span-4 flex items-center justify-center">
        <Image
          removeWrapper
          alt={`${category}-${name}`}
          height={272}
          loading={"lazy"}
          src={imageSrc}
          width={272}
        />
      </div>
      <div className="group col-span-12 lg:col-span-7 xl:col-span-6 flex flex-col gap-xl justify-between">
        <div className="flex flex-col  flex-1 gap-xs w-full">
          <div className="flex">
            <h3 className={clsx(bodyText({ size: "3xl" }), "flex-1")}>
              {name}
            </h3>
            <Button
              isIconOnly
              className={clsx(
                button({
                  color: "white",
                  variant: "link",
                  radius: "none",
                  size: "sm",
                  underline: true,
                }),
                "lg:hidden",
              )}
              onPress={onOpen}
            >
              <IconInfo />
            </Button>
          </div>
          <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between gap-md">
            <ProductTags product={product} size="sm" />
            <div className={clsx(bodyText({ size: "xs" }), "flex gap-sm")}>
              <IconSize />
              &nbsp;
              {
                technicalData[
                  TechnicalSpecificationLabelKey.technical_data_item_1
                ]
              }
            </div>
          </div>
        </div>
        {windowSize.width >= 1024 && (
          <div className="grid grid-cols-12 gap-x-xl gap-y-md xl:gap-y-xl">
            <div className="group col-span-6 lg:col-span-6 xl:col-span-4">
              <h4
                className={clsx(
                  bodyText({ size: "sm" }),
                  "border-b border-b-neutral-200 px-sm py-xs",
                )}
              >
                {t("technical-data")}
              </h4>
              <div className="flex flex-col gap-[10px] px-[12px] py-[10px]">
                <div
                  className={clsx(
                    bodyText({ size: "xs" }),
                    "flex flex-col gap-[4px]",
                  )}
                >
                  <strong>
                    {t(
                      TECHNICAL_SPECIFICATION_LABELS_SHORT[
                        TechnicalSpecificationLabelKey.technical_data_item_3
                      ],
                    )}
                  </strong>
                  {
                    technicalData[
                      TechnicalSpecificationLabelKey.technical_data_item_3
                    ]
                  }
                </div>
                <div
                  className={clsx(
                    bodyText({ size: "xs" }),
                    "flex flex-col gap-[4px]",
                  )}
                >
                  <strong>
                    {t(
                      TECHNICAL_SPECIFICATION_LABELS_SHORT[
                        TechnicalSpecificationLabelKey.technical_data_item_9
                      ],
                    )}
                  </strong>
                  {
                    technicalData[
                      TechnicalSpecificationLabelKey.technical_data_item_9
                    ]
                  }
                </div>
                <div
                  className={clsx(
                    bodyText({ size: "xs" }),
                    "flex flex-col gap-[4px]",
                  )}
                >
                  <strong>
                    {t(
                      TECHNICAL_SPECIFICATION_LABELS_SHORT[
                        TechnicalSpecificationLabelKey.technical_data_item_14
                      ],
                    )}
                  </strong>
                  {
                    technicalData[
                      TechnicalSpecificationLabelKey.technical_data_item_14
                    ]
                  }
                </div>
                <div
                  className={clsx(
                    bodyText({ size: "xs" }),
                    "flex flex-col gap-[4px]",
                  )}
                >
                  <strong>
                    {t(
                      TECHNICAL_SPECIFICATION_LABELS_SHORT[
                        TechnicalSpecificationLabelKey.technical_data_item_15
                      ],
                    )}
                  </strong>
                  {
                    technicalData[
                      TechnicalSpecificationLabelKey.technical_data_item_15
                    ]
                  }
                </div>
              </div>
            </div>
            <div className="group col-span-6 lg:col-span-6 xl:col-span-4">
              <h4
                className={clsx(
                  bodyText({ size: "sm" }),
                  "border-b border-b-neutral-200 px-sm py-xs",
                )}
              >
                {t("cutting-range")}
              </h4>
              <div className="flex flex-col">
                {cuttingRange.map(([label, values]) => (
                  <div
                    key={label}
                    className={clsx(
                      bodyText({ size: "xs" }),
                      "flex gap-sm justify-between px-[12px] py-[10px] whitespace-nowrap",
                    )}
                    onBlur={handleCuttingRange(null)}
                    onFocus={handleCuttingRange(label)}
                    onMouseOut={handleCuttingRange(null)}
                    onMouseOver={handleCuttingRange(label)}
                  >
                    <strong>
                      {/* @ts-ignore */}
                      {t(TECHNICAL_SPECIFICATION_LABELS_SHORT[label])}
                    </strong>
                    <span className="flex gap-sm justify-end">
                      <ProductCutsRange
                        values={values as unknown as string[]}
                      />
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="group col-span-12 lg:col-span-12 xl:col-span-4 flex justify-center md:justify-end lg:justify-center">
              <ProductCutsDetailed product={product} />
            </div>
          </div>
        )}

        <div>
          <Button
            as={Link}
            className={clsx(
              button({
                color: "primary",
                size: "sm",
                radius: "full",
                fullWidth: true,
              }),
              "w-full md:w-auto",
            )}
            endContent={<IconArrowRight />}
            href={productLink}
            type="submit"
          >
            {t("view-detail")}
          </Button>
        </div>
      </div>

      <>
        <Modal
          classNames={{
            header: "px-md",
            closeButton: "text-xl",
            body: "mt-sm px-md",
            footer: "justify-center px-md pb-xl",
          }}
          isDismissable={false}
          isKeyboardDismissDisabled={true}
          isOpen={isOpen}
          placement="center"
          onOpenChange={onOpenChange}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalBody>
                  <ProductDetailSpecification {...product} />
                </ModalBody>
                <ModalFooter>
                  <Button
                    radius="full"
                    size="sm"
                    startContent={<IconCrossSmall />}
                    variant="bordered"
                    onPress={onClose}
                  >
                    {tCommon("close-popup")}
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    </article>
  );
}
