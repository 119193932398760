"use client";

import clsx from "clsx";
import { useLocale, useTranslations } from "next-intl";

import { Boxes, BoxItem } from "../box";

import { IconCubes, IconProcess } from "@/icons/svgr";

interface Props {
  className?: string;
}

export default function Service({ className }: Props) {
  const t = useTranslations("common");
  const locale = useLocale();

  return (
    <Boxes
      className={clsx(
        "container max-w-1360 grid grid-cols-2 py-section",
        className,
      )}
    >
      <BoxItem
        Icon={<IconCubes />}
        link={`/${locale}/service?subject=spare-parts`}
      >
        {t("spare-parts")}
      </BoxItem>
      <BoxItem
        Icon={<IconProcess />}
        link={`/${locale}/service?subject=service`}
      >
        {t("service")}
      </BoxItem>
    </Boxes>
  );
}
