"use client";

import "@/styles/swiper.css";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { register } from "swiper/element/bundle";
import {
  Swiper,
  SwiperProps,
  SwiperSlide,
  SwiperSlideProps,
} from "swiper/react";
import { FreeMode, Thumbs } from "swiper/modules";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { Button, Image } from "@nextui-org/react";
import clsx from "clsx";

import { Product as ProductInterface } from "../../../app/api/models/types";
import { getProductImageFolder } from "../../../utils/products";

import { IconPause, IconPlay } from "@/icons/svgr";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "swiper-container": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperProps,
        HTMLElement
      >;
      "swiper-slide": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperSlideProps,
        HTMLElement
      >;
    }
  }
}

interface Props {
  product: ProductInterface;
}

register();

function Video({
  videoSrc,
  videoThumbsSrc,
}: {
  videoSrc: string;
  videoThumbsSrc: string;
}) {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayButtonClick = useCallback(() => {
    if (isPlaying) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  }, [isPlaying]);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener("play", handlePlay);
      videoElement.addEventListener("pause", handlePause);
    }

    // Vyčistit event listenery při unmountování komponenty
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
      }
    };
  }, []);

  return (
    <div className="relative group">
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={videoRef}
        controls
        height={580}
        poster={videoThumbsSrc}
        preload="none"
        width={580}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>
      <Button
        isIconOnly
        className={clsx(
          "absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 after:absolute after:top-0 after:left-0 after:w-full after:h-full",
          {
            isButtonVisible: "fade-in-out",
          },
        )}
        color="default"
        radius="full"
        onClick={handlePlayButtonClick}
      >
        {isPlaying ? <IconPause /> : <IconPlay />}
      </Button>
    </div>
  );
}

export default function ProductImageSwiper({ product }: Props): JSX.Element {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const { name, category } = product;

  // @ts-ignore
  const handleOnSwiper = (e) => {
    setThumbsSwiper(e);
  };

  const imagesSrc = (product.gallery || []).map(
    (imageSrc) => `${getProductImageFolder(product)}/${imageSrc}.png`,
  );

  const thumbsSrc = (product.gallery || []).map(
    (imageSrc) => `${getProductImageFolder(product)}/${imageSrc}.png`,
  );

  const videosSrc = (product.video || []).map(
    (videoSrc) => `${getProductImageFolder(product)}/${videoSrc}.mp4`,
  );

  const videoThumbsSrc = (product.video || []).map(
    (imageSrc) => `${getProductImageFolder(product)}/${imageSrc}-video.jpg`,
  );

  return (
    <div className="w-full py-xl">
      <Swiper
        className="swiper"
        modules={[Thumbs]}
        navigation={true}
        slidesPerView={1}
        spaceBetween={10}
        style={{
          // @ts-ignore
          "--swiper-navigation-color": "#1234B0",
          "--swiper-pagination-color": "#1234B0",
        }}
        thumbs={{ swiper: thumbsSwiper }}
      >
        {imagesSrc.map((imageSrc) => (
          <SwiperSlide key={imageSrc} className="px-2xl">
            <Image
              alt={`${category}-${name}`}
              height={580}
              loading={"lazy"}
              radius="none"
              src={imageSrc}
              width={580}
            />
          </SwiperSlide>
        ))}
        {videosSrc.map((videoSrc, index) => (
          <SwiperSlide key={videoSrc} className="px-2xl">
            <Video videoSrc={videoSrc} videoThumbsSrc={videoThumbsSrc[index]} />
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        className="swiper-thumbnails"
        freeMode={true}
        modules={[FreeMode, Thumbs]}
        slidesPerView={4}
        spaceBetween={10}
        watchSlidesProgress={true}
        onSwiper={handleOnSwiper}
      >
        {thumbsSrc.map((thumbSrc) => (
          <SwiperSlide key={thumbSrc}>
            <Image
              alt={`${category}-${name}`}
              height={136}
              loading={"lazy"}
              radius="none"
              src={thumbSrc}
              width={136}
            />
          </SwiperSlide>
        ))}
        {videoThumbsSrc.map((videoThumbSrc) => (
          <SwiperSlide key={videoThumbSrc}>
            <Image
              alt={`${category}-${name}`}
              height={136}
              loading={"lazy"}
              radius="none"
              src={videoThumbSrc}
              width={136}
            />
            <Button
              isIconOnly
              className="absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 scale-75 z-10"
              radius="full"
            >
              <IconPlay />
            </Button>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
