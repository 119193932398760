"use client";

import React, { useState } from "react";
import clsx from "clsx";
import { Image } from "@nextui-org/react";
import { register } from "swiper/element/bundle";
import {
  Swiper,
  SwiperProps,
  SwiperSlide,
  SwiperSlideProps,
} from "swiper/react";

import "./swiper.css";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import {
  Products as ProductsInterface,
  Product as ProductInterface,
} from "../../../app/api/models/types";
import ProductTags from "../tags";

import { HighlightedProduct } from "./item";

import { getProductImageFolder } from "@/utils/products";
import { bodyText } from "@/components/primitives";
import { ProductProvider } from "@/contexts/product";
import useWindowSize from "@/hooks/useWindowSize";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "swiper-container": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperProps,
        HTMLElement
      >;
      "swiper-slide": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperSlideProps,
        HTMLElement
      >;
    }
  }
}

register();

interface Props {
  products?: ProductsInterface;
  highlighted: ProductInterface["name"][];
}

export default function HighlightedProducts({
  products = [],
  highlighted = [],
}: Props) {
  const windowSize = useWindowSize();
  const [miniaturesSwiper, setMiniaturesSwiper] = useState(null);

  // @ts-ignore
  const handleOnSwiper = (e) => {
    setMiniaturesSwiper(e);
  };

  const highlightedProducts = products.reduce((acc, product) => {
    if (
      highlighted.includes(product.name) &&
      !acc.some(({ name }) => name === product.name)
    ) {
      acc.push(product);
    }

    return acc;
  }, [] as ProductsInterface);

  if (!products) {
    return null;
  }

  return (
    <>
      <div className="w-full flex flex-col gap-xl lg:gap-section-small lg:gap-section text-left overflow-hidden">
        <div className="w-full flex flex-1">
          <Swiper
            slidesPerView={1}
            spaceBetween={48}
            thumbs={{ swiper: miniaturesSwiper }}
          >
            {highlightedProducts?.map((product) => {
              return (
                <SwiperSlide key={product.name}>
                  <ProductProvider>
                    <HighlightedProduct {...product} />
                  </ProductProvider>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        {windowSize.width >= 1024 && (
          <div className="w-full">
            <Swiper
              className="swiper-product-thumbnails "
              slidesPerView={4}
              spaceBetween={16}
              onSwiper={handleOnSwiper}
            >
              {highlightedProducts?.map((product) => {
                const imageSrc = `${getProductImageFolder(product)}/${product.gallery[0]}.png`;

                return (
                  <SwiperSlide key={product.name}>
                    <div
                      key={product.name}
                      className={clsx(
                        "bg-neutral-050 grid grid-cols-2 relative w-full h-full p-md rounded-lg cursor-pointer transition-colors",
                      )}
                    >
                      <div className="group col-span-2 lg:col-span-1 flex justify-center min-h-[128px]">
                        <Image
                          removeWrapper
                          alt={product.name}
                          height={76}
                          loading={"lazy"}
                          src={imageSrc}
                          width={101}
                        />
                      </div>
                      <div className="static group col-span-2 lg:col-span-1 flex flex-col justify-center gap-sm">
                        <span
                          className={clsx(
                            bodyText({ size: "lg", weight: "700" }),
                          )}
                        >
                          {product.name}
                        </span>
                        <ProductTags product={product} size="sm" />
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        )}
      </div>
    </>
  );
}
