"use client";

import "./swiper.css";

import React, { useState } from "react";
import { register } from "swiper/element/bundle";
import {
  Swiper,
  SwiperProps,
  SwiperSlide,
  SwiperSlideProps,
  useSwiper,
} from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/parallax";
import "swiper/css/navigation";

import { Button, ButtonGroup, Image, Link } from "@nextui-org/react";
import clsx from "clsx";
import { Parallax } from "swiper/modules";
import { useLocale, useTranslations } from "next-intl";

import {
  Products as ProductsInterface,
  Product as ProductInterface,
} from "../../../app/api/models/types";

import { getProductImageFolder } from "@/utils/products";
import { ProductCategory } from "@/app/api/models/enums";
import { bodyText, title } from "@/components/primitives";
import { IconArrowRight } from "@/icons/svgr";
import useWindowSize from "@/hooks/useWindowSize";
import { button } from "@/components/button";
import { Section, Wrapper } from "@/components/section";
import ErrorBoundary from "@/components/error-boundary";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "swiper-container": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperProps,
        HTMLElement
      >;
      "swiper-slide": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & SwiperSlideProps,
        HTMLElement
      >;
    }
  }
}

register();

interface Props {
  className?: string;
  products?: ProductsInterface;
  selected: ProductInterface["name"][];
}

function getProductData(product: ProductInterface, locale: string) {
  if (product.category === ProductCategory.semiAutomat) {
    return {
      heading: "category-1.heading",
      description: "category-1.description",
      link: `/${locale}/models?category=semi-automat`,
      linkLabel: "category-1.link-label",
    };
  }

  if (product.category === ProductCategory.automat) {
    return {
      heading: "category-2.heading",
      description: "category-2.description",
      link: `/${locale}/models?category=automat`,
      linkLabel: "category-2.link-label",
    };
  }

  if (product.category === ProductCategory.awd) {
    return {
      heading: "category-3.heading",
      description: "category-3.description",
      link: `/${locale}/models?category=awd`,
      linkLabel: "category-3.link-label",
    };
  }

  return {};
}

function ProductSwiperVerticalPagination() {
  const swiper = useSwiper();
  const t = useTranslations("product-swiper-vertical");

  return (
    <div className="absolute left-0 bottom-2xl w-full flex justify-center z-10">
      <ButtonGroup radius="full">
        <Button
          className="bg-white text-xl text-gray-darker"
          size="lg"
          onClick={() => swiper.slideTo(0)}
        >
          {t("semi-automatic-machines")}
        </Button>
        <Button
          className="bg-white text-xl text-gray-darker"
          size="lg"
          onClick={() => swiper.slideTo(1)}
        >
          {t("automatic-machines")}
        </Button>
        <Button
          className="bg-white text-xl text-gray-darker"
          size="lg"
          onClick={() => swiper.slideTo(2)}
        >
          {t("awd")}
        </Button>
      </ButtonGroup>
    </div>
  );
}

function ProductSwiperVerticalMobile({
  selectedProducts,
}: {
  selectedProducts: ProductsInterface;
}) {
  const [descriptionSwiper, setDescriptionSwiper] = useState(null);
  const t = useTranslations("product-swiper-vertical");
  const locale = useLocale();

  // @ts-ignore
  const handleOnSwiper = (e) => {
    setDescriptionSwiper(e);
  };

  return (
    <Section
      className="overflow-hidden relative h-[648px]"
      padding="none"
      width="2xl"
    >
      <Wrapper className="h-full bg-box-2 rounded-xl px-lg pb-0 text-white text-left flex flex-col items-center">
        <div className="h-full flex flex-1 flex-col gap-2xl max-w-[529px]">
          <h2
            className={clsx(
              bodyText({ size: "4xl" }),
              "lg:pt-md lg:pt-section xl:pt-section-large",
            )}
          >
            {t.rich("heading", {
              br: () => <br />,
            })}
          </h2>

          <Swiper
            parallax
            className="swipper-vertical-mobile flex flex-1 w-full"
            direction={"vertical"}
            modules={[Parallax]}
            slidesPerView={1}
            spaceBetween={400}
            speed={1200}
          >
            {selectedProducts.map((product) => {
              const { heading, description, link, linkLabel } = getProductData(
                product,
                locale,
              );
              const imageSrc = `${getProductImageFolder(product)}/${product.gallery[0]}.png`;

              return (
                <SwiperSlide key={product.name}>
                  <div className="relative flex flex-col items-center gap-3xl">
                    <div
                      className="flex flex-1_ flex-col items-start justify-center_ gap-xl pt-xl_"
                      data-swiper-parallax="300"
                    >
                      <div>
                        <h3 className={title({ size: "h2" })}>{t(heading)}</h3>
                        <p
                          className={clsx(
                            bodyText({ size: "xl", color: "gray-ligher" }),
                            "py-sm",
                          )}
                        >
                          {t(description)}
                        </p>
                      </div>

                      <Button
                        as={Link}
                        className="text-white border-white"
                        color="default"
                        endContent={<IconArrowRight />}
                        href={link}
                        radius="full"
                        variant="bordered"
                      >
                        {t(linkLabel)}
                      </Button>
                    </div>

                    <div
                      className="flex items-center h-[640px] bg-box rounded-lg"
                      data-swiper-parallax="-600"
                    >
                      <Image
                        removeWrapper
                        alt={product.name}
                        height={528}
                        loading={"lazy"}
                        src={imageSrc}
                        width={528}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </Wrapper>
    </Section>
  );
}

function ProductSwiperVerticalDesktop({
  selectedProducts,
}: {
  selectedProducts: ProductsInterface;
}) {
  const [descriptionSwiper, setDescriptionSwiper] = useState(null);
  const t = useTranslations("product-swiper-vertical");
  const locale = useLocale();

  // @ts-ignore
  const handleOnSwiper = (e) => {
    setDescriptionSwiper(e);
  };

  return (
    <Section
      className=""
      innerClassName="bg-box-2 rounded-xl overflow-hidden relative"
      width="2xl"
    >
      <Wrapper className="lg:px-xl xl:px-0" width="lg">
        <div className="grid grid-cols-2 gap-xl lg:gap-2xl max-h-[800px] lg:max-h-[800px] text-white text-left px-xl lg:px-0">
          <div className="group col-span-2 lg:col-span-1 max-w-[528px] flex flex-col items-start gap-lg lg:gap-3xl lg:pr-2xl_">
            <h2
              className={clsx(
                bodyText({ size: "4xl" }),
                "lg:pt-md lg:pt-section xl:pt-section-large",
              )}
            >
              {t.rich("heading", {
                br: () => <br />,
              })}
            </h2>

            <Swiper
              autoHeight
              className="flex-1 max-h-1/2 swiperDescription"
              direction={"vertical"}
              followFinger={false}
              slidesPerView={1}
              watchSlidesProgress={true}
              onSwiper={handleOnSwiper}
            >
              {selectedProducts.map((product) => {
                const { heading, description, link, linkLabel } =
                  getProductData(product, locale);

                return (
                  <SwiperSlide key={product.name}>
                    <div className="flex flex-col items-start gap-xl">
                      <div className="pointer-events-none">
                        <h3 className={title({ size: "h2" })}>{t(heading)}</h3>
                        <p
                          className={clsx(
                            bodyText({ size: "xl", color: "gray-ligher" }),
                            "py-sm",
                          )}
                        >
                          {t(description)}
                        </p>
                      </div>

                      <Button
                        as={Link}
                        className={button({
                          color: "white",
                          radius: "full",
                          size: "sm",
                          variant: "bordered",
                        })}
                        endContent={<IconArrowRight />}
                        href={link}
                      >
                        {t(linkLabel)}
                      </Button>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="group col-span-2 lg:col-span-1 overflow-hidden">
            <Swiper
              autoHeight
              className="!static"
              direction={"vertical"}
              spaceBetween={100}
              thumbs={{ swiper: descriptionSwiper }}
            >
              {selectedProducts.map((product) => {
                const imageSrc = `${getProductImageFolder(product)}/${product.gallery[0]}.png`;

                return (
                  <SwiperSlide
                    key={product.name}
                    className="!flex items-center h-[800px] max-h-[800px] lg:max-h-[800px]"
                  >
                    <div className="flex items-center h-[680px] max-h-[680px] bg-box p-xl rounded-lg overflow-hidden">
                      <Image
                        removeWrapper
                        alt={product.name}
                        height={528}
                        loading={"lazy"}
                        src={imageSrc}
                        width={528}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}

              <ProductSwiperVerticalPagination />
            </Swiper>
          </div>
        </div>
      </Wrapper>
    </Section>
  );
}

export default function ProductSwiperVertical({
  className,
  products = [],
  selected = [],
}: Props): JSX.Element {
  const windowSize = useWindowSize();

  const selectedProducts = products.reduce((acc, product) => {
    if (
      selected.includes(product.name) &&
      !acc.some(({ name }) => name === product.name)
    ) {
      acc.push(product);
    }

    return acc;
  }, [] as ProductsInterface);

  if (windowSize.width < 1024) {
    return (
      <ErrorBoundary>
        <ProductSwiperVerticalMobile selectedProducts={selectedProducts} />
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <ProductSwiperVerticalDesktop selectedProducts={selectedProducts} />
    </ErrorBoundary>
  );
}
