import { ReactNode } from "react";
import clsx from "clsx";

interface Props {
  className?: string;
  children: ReactNode;
}

export default function Boxes({ className, children }: Props) {
  return (
    <div className={clsx(className, "gap-md lg:gap-xl text-left")}>
      {children}
    </div>
  );
}
