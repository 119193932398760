import { ReactNode } from "react";
import NextLink from "next/link";
import clsx from "clsx";

import {
  IconArrowRightLarge,
  IconShapeFirst,
  IconShapeSecond,
} from "../../../icons/svgr";

import { bodyText } from "@/components/primitives";

interface Props {
  className?: string;
  children: string;
  link: string;
  Icon: ReactNode;
}

export default function BoxItem({
  className,
  children,
  link = "#",
  Icon,
}: Props) {
  return (
    <div
      className={clsx(
        className,
        "group col-span-2 sm:col-span-1 relative bg-box p-xl lg:p-2xl rounded-lg text-white overflow-hidden transition-all duration-700",
        "hover:scale-95 hover:rounded-2xl",
      )}
    >
      <IconShapeFirst
        className={clsx(
          "absolute top-0 right-0 text-neutral-600 opacity-25 transition-all duration-700",
          "group-hover:scale-105 group-hover:translate-x-[50%] group-hover:translate-y-[-25%]",
        )}
      />
      <IconShapeSecond
        className={clsx(
          "absolute top-0 right-0 text-neutral-600 opacity-25 transition-all duration-700",
          "group-hover:scale-105 group-hover:translate-x-[5%] group-hover:text-primary group-hover:opacity-100",
        )}
      />
      <div className="flex gap-md items-center justify-between lg:pt-[40px] transition-transform duration-700 group-hover:scale-105">
        <div className="w-2xl h2-xl p-sm">{Icon}</div>
        <div className={clsx("flex-1", bodyText({ size: "2xl" }))}>
          <NextLink
            className="after:absolute after:top-0 after:left-0 after:w-full after:h-full after:z-[2]"
            href={link}
          >
            {children}
          </NextLink>
        </div>
        <IconArrowRightLarge className="relative z-[1]" />
      </div>
    </div>
  );
}
