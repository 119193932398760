import clsx from "clsx";
import { useContext } from "react";
import { useTranslations } from "next-intl";

import { Product as ProductInterface } from "../../../app/api/models/types";
import ProductCutsRange from "../cuts-range";
import ProductCutsDetailed from "../cuts-detailed";

import { bodyText } from "@/components/primitives";
import { TECHNICAL_SPECIFICATION_LABELS_SHORT } from "@/consts/product/technical-speficiation";
import {
  TechnicalSpecificationKey,
  TechnicalSpecificationLabelKey,
} from "@/app/api/models/enums";
import { ProductContext } from "@/contexts/product";

export default function ProductDetailSpecification(product: ProductInterface) {
  const { handleCuttingRange } = useContext(ProductContext);
  const t = useTranslations("product");

  const technicalData = product[TechnicalSpecificationKey.technical_data];
  const cuttingRange = Object.entries(
    product[TechnicalSpecificationKey.cutting_range],
  ).filter(([_label, value]) => value);

  return (
    <div className="grid grid-cols-12 gap-lg">
      <div className="group col-span-12">
        <h4
          className={clsx(
            bodyText({ size: "sm" }),
            "border-b border-b-neutral-200 px-sm py-xs",
          )}
        >
          {t("technical-data")}
        </h4>
        <div className="grid grid-cols-12">
          <div
            className={clsx(
              bodyText({ size: "xs" }),
              "group col-span-4 pl-[12px] py-[10px]",
            )}
          >
            <strong>
              {t(
                TECHNICAL_SPECIFICATION_LABELS_SHORT[
                  TechnicalSpecificationLabelKey.technical_data_item_3
                ],
              )}
            </strong>
            <br />
            {
              technicalData[
                TechnicalSpecificationLabelKey.technical_data_item_3
              ]
            }
          </div>
          <div
            className={clsx(
              bodyText({ size: "xs" }),
              "group col-span-8 pr-[12px] py-[10px]",
            )}
          >
            <strong>
              {t(
                TECHNICAL_SPECIFICATION_LABELS_SHORT[
                  TechnicalSpecificationLabelKey.technical_data_item_9
                ],
              )}
            </strong>
            <br />
            {
              technicalData[
                TechnicalSpecificationLabelKey.technical_data_item_9
              ]
            }
          </div>
          <div
            className={clsx(
              bodyText({ size: "xs" }),
              "group col-span-4 px-[12px] lg:pl-[12px] lg:py-[10px]",
            )}
          >
            <strong>
              {t(
                TECHNICAL_SPECIFICATION_LABELS_SHORT[
                  TechnicalSpecificationLabelKey.technical_data_item_14
                ],
              )}
            </strong>
            <br />
            {
              technicalData[
                TechnicalSpecificationLabelKey.technical_data_item_14
              ]
            }
          </div>
          <div
            className={clsx(
              bodyText({ size: "xs" }),
              "group col-span-8 pb-[10px] pr-[12px] py-[10px]",
            )}
          >
            <strong>
              {t(
                TECHNICAL_SPECIFICATION_LABELS_SHORT[
                  TechnicalSpecificationLabelKey.technical_data_item_15
                ],
              )}
            </strong>
            <br />
            {
              technicalData[
                TechnicalSpecificationLabelKey.technical_data_item_15
              ]
            }
          </div>
        </div>
      </div>
      <div className="group col-span-7">
        <h4
          className={clsx(
            bodyText({ size: "sm" }),
            "border-b border-b-neutral-200 px-sm py-xs",
          )}
        >
          {t("cutting-range")}
        </h4>
        <div className="flex flex-col">
          {cuttingRange.map(([label, values]) => (
            <div
              key={label}
              className={clsx(
                bodyText({ size: "xs" }),
                "flex gap-xl px-[12px] py-[10px] whitespace-nowrap",
              )}
              onBlur={handleCuttingRange(null)}
              onFocus={handleCuttingRange(label)}
              onMouseOut={handleCuttingRange(null)}
              onMouseOver={handleCuttingRange(label)}
            >
              <strong>
                {/* @ts-ignore */}
                {t(TECHNICAL_SPECIFICATION_LABELS_SHORT[label])}
              </strong>
              <span className="flex gap-sm justify-end">
                <ProductCutsRange
                  compact
                  values={values as unknown as string[]}
                />
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="group col-span-5 flex justify-center md:justify-end">
        <ProductCutsDetailed className="-mt-md scale-80" product={product} />
      </div>
    </div>
  );
}
